@media (max-width: 700px) {
    #chat,
    #subtitles {
        width: calc(100% - 20px);
    }

    .header-options button svg {
        height: 18px;
    }

    .header-title {
        font-size: 1em;
    }

    .hidden-mobile {
        display: none;
    }
}

@media (max-height: 450px) {
    .wrapper-login {
        height: 100%;
    }
}

@media (max-height: 200px) {
    .wrapper-login-form {
        display: flex;
        justify-content: space-between;
        flex-flow: wrap;
    }

    .login-inputs {
        width: 48%;
    }

    .wrapper-content {
        width: 700px;
    }
}
