.Button {
    padding: 0.9em 0;
    border-radius: 3px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1em;
    border: none;
    cursor: pointer;
    display: block;
    text-decoration: none;
}

.Button-small {
    font-size: 0.7em;
    padding: 0.7em;
}

.Button-large {
    font-size: 1.2em;
    padding: 1.2em;
}

.Button-primary {
    background-color: var(--main-bg-button-primary);
    color: var(--main-color-button-primary);
}

.Button-danger {
    background-color: red;
    color: white;
}

.Button-info {
    background-color: lightseagreen;
    color: white;
}

.Button-warning {
    background-color: yellow;
    color: white;
}

.Button-success {
    background-color: #50ac49;
    color: white;
}

.Button-default {
    background-color: #ccc;
    color: #555;
}

.Button-dashboard {
    background-color: #9acbd2;
    color: white;
}

.Button-block {
    width: 100%;
}

.Button:hover,
.Button:active,
.Button:focus {
    filter: brightness(0.8);
}
