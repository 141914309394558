:root {
    --main-bg-button-primary: #ef984e;
    --main-color-button-primary: white;
    --login-bg-primary: rgb(48, 169, 216);
    --header-bg-primary: rgb(1, 30, 100);
    --header-color-text: white;
    --gradient-primary: rgba(1, 30, 100, 1);
    --gradient-secondary: rgba(48, 169, 216, 1);
    --component-header-option: #999;
}

@import './components/header.css';
@import './components/login.css';
@import './components/main.css';
@import './components/override.css';
@import './components/responsive.css';

body {
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
}

.wrapper-container {
    background-color: white;
}

a,
button {
    cursor: pointer;
}

.btn-flex {
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-orange {
    background-color: #ef984e;
    color: white;
}

/* FLEXBOX UTILS */

.d-flex {
    display: flex;
}

.justify-content-between {
    justify-content: space-between;
}

.justify-content-center {
    justify-content: center;
}

.justify-content-end {
    justify-content: flex-end;
}

.justify-content-start {
    justify-content: flex-start;
}

.align-items-center {
    align-items: center;
}

.align-items-end {
    align-items: end;
}

.align-items-start {
    align-items: flex-start;
}

.loader-wrapper {
    width: 100vw;
    height: 100vh;
    background: #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader-img {
    height: 10vw;
}

.loader-title {
    text-align: 'center';
    color: '#999';
}